export const formatTime = (seconds, unit = null) => {
  const timeScale = [
    { range: [-Infinity, 90], scale: 1, label: "second", decimals: 0 },
    { range: [90, 5400], scale: 60, label: "minute", decimals: 1 },
    { range: [5400, 172800], scale: 3600, label: "hour", decimals: 1 },
    { range: [172800, 1209600], scale: 86400, label: "day", decimals: 1 },
    { range: [1209600, 4838400], scale: 604800, label: "week", decimals: 1 },
    { range: [4838400, Infinity], scale: 2592000, label: "month", decimals: 1 },
  ];

  // If unit is provided, scale seconds to the provided unit
  if (unit) {
    const scaleItem = timeScale.find(
      (s) => s.label === unit || s.label + "s" === unit,
    );
    if (scaleItem) {
      const value = (seconds / scaleItem.scale).toFixed(scaleItem.decimals);
      return { value: parseFloat(value), unit: unit };
    }
  }

  // If unit is not provided, scale dynamically
  const scale = timeScale.find(
    (s) => seconds >= s.range[0] && seconds < s.range[1],
  );
  const value = (seconds / scale.scale).toFixed(scale.decimals);
  const outputUnit = `${scale.label}${value === "1" ? "" : "s"}`;
  return { value: parseFloat(value), unit: outputUnit };
};
